<script setup lang="ts">
import type { WithContext, BreadcrumbList } from 'schema-dts'
import type { TJobBoardArticle } from '@tl-strapi/types/TJobBoardArticle'

const { t, locale } = useI18n()
const toLocalePath = useLocalePath()
const { status: authStatus } = useAuth()

const route = useRoute()
const runtimeConfig = useRuntimeConfig()

const siteUrl = runtimeConfig.public.siteUrl
const pageUrl = `${siteUrl}${route.path}`
let ogTitle = runtimeConfig.public.siteName
let ogImageUrl = `${siteUrl}/__og-image__/image${route.path}/og.png`
let ogDescription = `Discover premium technology and digital job opportunities in Asia with ${runtimeConfig.public.siteName}. As the leading job board specializing in the tech industry, we connect talented professionals with top-tier companies offering exceptional working environments, competitive packages, and exciting career prospects. Explore our extensive listings to find your next career move in software development, data science, digital marketing, UX/UI design, cybersecurity, and more.`

const refId = (route.query.ref_code || '') as string

// Referral redirection logic
if (refId) {
  if (refId.startsWith('ss_')) {
    ogImageUrl =
      locale.value === 'en'
        ? `${siteUrl}/images/og-images/hk-salary-survey-eng.png`
        : `${siteUrl}/images/og-images/hk-salary-survey-v2.png`

    ogTitle = `${t('jobBoard.salarySurveyEvent.ogtitle')} | ${runtimeConfig.public.siteName}`
    ogDescription = `${t('jobBoard.salarySurveyEvent.ogdescriptionThankYouPage')}`
    await navigateTo(
      toLocalePath({
        path: '/hk-events/hk-salary-survey',
        query: {
          ref_code: refId
        }
      })
    )
  }
}

const jobModelResumeReviewShow = ref<boolean>(false)

defineOgImage({
  component: 'Public'
})

useSeoMeta({
  title: () => ogTitle,
  description: () => ogDescription,
  ogSiteName: () => runtimeConfig.public.siteName,
  ogTitle: () => runtimeConfig.public.siteName,
  ogDescription: () => ogDescription,
  ogUrl: () => pageUrl,
  ogImageUrl: () => ogImageUrl,
  ogImageHeight: 630,
  ogImageWidth: 1200,
  ogImageAlt: () => runtimeConfig.public.siteName,
  ogImageType: 'image/png',
  ogImageSecureUrl: () => ogImageUrl,
  twitterCard: 'summary_large_image',
  twitterTitle: runtimeConfig.public.siteName,
  twitterDescription: () => ogDescription,
  twitterImage: () => ogImageUrl
})

definePageMeta({
  layout: 'public',
  middleware: ['tlskip']
})

// Get latest articles
const request = getLatestArticlesRequest(1, 4, true)
const { data: articleData, pending: articlePending } = await useStrapiList<TJobBoardArticle>(
  request.pathname,
  request.query,
  {
    server: true,
    lazy: process.client
  }
)

const partners: {
  url: string
  width: number
  height: number
}[] = [
  {
    url: '/images/partners/google.png',
    width: 75.625,
    height: 25
  },
  {
    url: '/images/partners/aws.png',
    width: 80,
    height: 30
  },
  {
    url: '/images/partners/gitlab.png',
    width: 90,
    height: 20
  },
  {
    url: '/images/partners/hkt.png',
    width: 320,
    height: 80
  },
  {
    url: '/images/partners/pwc.png',
    width: 48,
    height: 30
  }
]

// structured data of breadcrumb
const breadcrumb: WithContext<BreadcrumbList> = {
  '@context': 'https://schema.org',
  '@type': 'BreadcrumbList',
  itemListElement: [
    {
      '@type': 'ListItem',
      position: 1,
      name: runtimeConfig.public.siteName,
      item: siteUrl
    }
  ]
}

useDefaultJsonld([breadcrumb])
</script>

<template>
  <TopBotSplitLayout bottom-classes="bg-gray-10 py-[1.25rem] lg:py-[3.12rem] lg:pt-[0.62rem]">
    <template #top>
      <h1 class="m-auto mb-[1.25rem] w-fit text-center text-h2m font-medium md:text-h2">
        {{ $t('jobBoard.indexPage.heading') }}
      </h1>
      <SearchDesktop :show-filters="false" :show-filter-tags="false" :show-hot-tags="true" />
    </template>
    <template #bottom>
      <div class="flex flex-col gap-[0.62rem] pb-[0.62rem]">
        <div class="text-center text-gray-50">{{ $t('jobBoard.indexPage.trustedBy') }}</div>
        <div
          class="mx-auto grid max-w-[40.91rem] grid-cols-5 items-center gap-4 md:auto-cols-auto md:grid-flow-col md:gap-[3.12rem]"
        >
          <div v-for="(partner, idx) in partners" :key="idx" class="m-auto md:w-fit">
            <nuxt-picture
              format="webp"
              background="transparent"
              :src="partner.url"
              alt="company logo"
              :width="partner.width"
              :height="partner.height"
              fit="contain"
              position="left top"
            />
          </div>
        </div>
      </div>
      <div class="pt-0 lg:pt-[3.12rem]">
        <LeftMainRightSplitLayout>
          <template #left>
            <div class="flex flex-col gap-[1.88rem]">
              <JobListMain
                :query="{
                  status: 'PUBLISHED',
                  page: 1,
                  page_size: 2,
                  order_by: '-posting_date',
                  hide_from_latest: false
                }"
                :text="t('jobBoard.sectionHeading.latestJobs')"
                :see-more="true"
                heading-to="/jobs"
                lazy="CLIENT_ONLY"
              />
              <JobCampaignListMain lazy="CLIENT_ONLY" />
              <JobListMain
                :query="{
                  status: 'PUBLISHED',
                  page: 1,
                  page_size: 10,
                  order_by: '-modified_date',
                  featured: true
                }"
                :text="t('jobBoard.sectionHeading.featureJobs')"
                :see-more="false"
                lazy="CLIENT_ONLY"
              />
            </div>
          </template>
          <template #right>
            <div class="flex flex-col gap-[1.88rem]">
              <LazyArticleSideList
                :articles="articleData?.data"
                :loading="articlePending"
                :heading="$t('jobBoard.sectionHeading.newsAndInsight')"
                heading-to="/articles/latest"
              />
              <SvgCVReviewCard @click="jobModelResumeReviewShow = true" />
              <EventCcf2025SideCard />
              <EventHktdcEduCareerExpoSideCard />
              <PromotionCardSide />
              <!-- <EventCyberportCareer2024SideCard />
              <EventSalarySurveySideCard /> -->

              <JobModalResumeReview
                ui-location="HOME_PAGE"
                :show="jobModelResumeReviewShow"
                @update:show="jobModelResumeReviewShow = $event"
              ></JobModalResumeReview>
            </div>
          </template>
        </LeftMainRightSplitLayout>
      </div>

      <div class="flex flex-col gap-[3.12rem] py-[1.88rem] md:py-[9.38rem]">
        <h2 class="text-h2 font-medium md:text-h1">
          {{ $t('jobBoard.indexPage.bottomHeading') }} <span class="text-secondary-2">:}</span>
        </h2>
        <div>
          <ButtonPurpleButton
            :text="$t('jobBoard.navBar.getStarted')"
            @click="
              navigateTo(
                authStatus === 'authenticated' ? toLocalePath('jobs') : toLocalePath('auth-signup')
              )
            "
          >
            <template #right-icon>
              <IconsFigmaArrowForwardOutlined />
            </template>
          </ButtonPurpleButton>
        </div>
      </div>
    </template>
  </TopBotSplitLayout>
</template>
